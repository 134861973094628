import Repository from "@/modules/common/utils/Repository";

export default {
  find() {
    return Repository.mainApi.get('projects/?per_page=1000')
  },
  get(id) {
    let endpoint = `projects/${id ? id : ''}`
    return Repository.mainApi.get(endpoint)
  },
  create(project) {
    return Repository.mainApi.post('projects/', project)
  },
  remove(projectId) {
    return Repository.mainApi.delete(`projects/${projectId}`)
  },
  searchArticles(projectId, filters = {}) {
    let filterData = {
      ...filters
    }
    return Repository.ilmApi.post(`projects/${projectId}/articles/search`, filterData)
  },
  getArticle(projectId, articleId) {
    return Repository.ilmApi.get(`projects/${projectId}/articles/${articleId}`)
  },
  filtersAutocomplete(projectId, filters = {}) {
    return Repository.ilmApi.post(`projects/${projectId}/articles/autocomplete`, filters)
  },
  setStarredArticle(projectId, articleId, starred) {
    let data = {"starred": starred}
    return Repository.ilmApi.post(`projects/${projectId}/articles/${articleId}/set_starred`, data)
  },
  patch(projectId, name, active){
    let data = {project_name:name, active:active}
    return Repository.mainApi.patch(`projects/${projectId}`, data)
  }
}
