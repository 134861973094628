<template>
  <span class="pointer vertical-align-star" @click="action">
    <v-icon v-if="favorite" color="amber lighten-1">mdi-star</v-icon>
    <v-icon v-else>mdi-star-outline</v-icon>
  </span>
</template>

<script>
export default {

  name: 'FavoriteButton',

  props: {
    favorite: {
      type: Boolean,
      default: false
    },
    action: Function
  }
}
</script>

<style lang="css" scoped>

.vertical-align-star {
  vertical-align:text-top;
}
</style>