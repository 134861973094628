<template>
  <div v-if="ontologyChartData.length != 0 && chartData.keys != 0">
    <div class="breadcrumb">
      <span class="fz-16 openSans-400 txt-primary-black" v-if="rootLevel">Knowledge Graph</span>
      <span v-else><a @click="drawOntologyChart">Knowledge Graph</a> / {{ chartData.title }}</span>
    </div>
    <horizontal-bar-chart @select="drawKeywordChart" v-if="ontologyChartData && chartData" :chart-data="chartData"></horizontal-bar-chart>
  </div>
</template>

<script>
import HorizontalBarChart from "@/modules/common/components/HorizontalBarChart";
export default {
  name: "OntologyTagChart",
  components: {HorizontalBarChart},
  props: {ontologyChartData: Array},
  data() {
    return {
      chartData:{},
      rootLevel: true,
    }
  },
  methods : {
    drawOntologyChart() {
      let deduplicated = this.ontologyChartData.reduce((unique, item) => unique.some((e) => e.ontology_name == item.ontology_name) ? unique: [...unique, item], [])
      let data = deduplicated.map(o => {
        return {
          name: o.ontology_name,
          total :o.keyword.length,
          hasChildren: true,
          keyword:o.keyword
        }
      })
      this.chartData = {title: "Categories", data: data}
      this.rootLevel = true
    },
    drawKeywordChart(ontology) {
      let data = ontology.keyword.map(k => {
        return {
          name: k.keyword,
          total: k.synonyms.length,
          hasChildren: false
        }
      })
      this.chartData = {title: ontology.name, data: data}
      this.rootLevel = false
    }
  },
  created() {
    this.drawOntologyChart()
  }
}
</script>

<style scoped>
div.breadcrumb {
  /*font-size: 0.8em;*/
  margin: 5px 0px;
}
</style>