<template>
  <div class="pointer" @click="action">
    <v-icon v-if="expanded">mdi-chevron-up</v-icon>
    <v-icon v-else>mdi-chevron-down</v-icon>
  </div>
</template>

<script>
export default {

  name: 'ExpandButton',

  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    action: Function
  }
}
</script>

<style lang="css" scoped>
</style>