<template>
  <div class="chart">
    <div class="bar-row">
      <v-col cols="1">
        <div class="align-self-center chart-title"
             v-text="formattedTitle(chartData.title)"></div>
      </v-col>
      <v-col cols="10" lg="10">
        <div class="bar-row" v-for="barData in chartData.data" :key="barData.name">
          <div class="bar-title" v-text="formattedTitle(barData.name)"></div>
          <div :class="getBarClass(barData)">
            <v-icon v-if="barData.hasChildren" small @click="click(barData)">mdi-chevron-right</v-icon>
          </div>
          <div class="bar-total" v-text="barData.total"></div>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalBarChart",
  props: {
    chartData: Object
  },
  data() {
    return {
    }
  },
  methods: {
    click(target) {
      this.$emit('select', target)
    },
    getBarClass(barData) {
      return `percent percent-${Math.trunc(barData.total / this.total * 50)}`
    },
    formattedTitle(title) {
      return title.replaceAll("_", " ").toLowerCase()
    }
  },
  computed:{
    total() {
      return this.chartData.data.map(e => e.total).reduce((tot, current) => tot + current)
    },
    max() {
      return Math.max(this.chartData.data.map(e => e.total))
    }
  }
}
</script>

<style lang="scss" scoped>

div.bar-title {
  //width: 130px;
  justify-content: flex-end;
  text-transform: capitalize;
  display: flex;
  flex: 1 0 17%;
  max-width: 17%;
  margin-right: 20px;
}
div.bar-total {
  margin-left: 10px;
}
div.bar-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  align-items: center;
}
div.percent {
  line-height: 2;
  text-transform: uppercase;
  width: 100%;
  height: 26px;
  content: "";
  display: block;
  background-color: #3d9970;
  margin-top: 5px;
  margin-bottom: 5px;
  //width: 50px;
  //position: relative;
  //top: 50%;
  //transition: background-color .3s ease;
  //cursor: pointer;
  border-radius: 2px;
  text-align: right;
  &:hover {
    filter: brightness(120%);
  }
}

@for $i from 1 through 100 {
  div.percent-#{$i} {
    $value: ($i * 1%);
    width: $value;
  }
}

div.chart {
  max-width: 700px;
  border: 1px solid lightgrey;
  font-size: .8em;
  padding: 3px;
}

div.chart-title {
  transform: rotate(180deg);
  font-size: small;
  text-align: center;
  color: grey;
  text-transform: capitalize;
  writing-mode: vertical-lr;
}
</style>