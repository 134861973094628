export default {

  copyToClipboard(text) {
    const textElem = document.createElement('textarea')
    textElem.value = text
    document.body.appendChild(textElem)
    textElem.select()
    document.execCommand('copy')
    document.body.removeChild(textElem)
  },

  goToExternalUrl(url) {
    window.location.replace(url)
  },

  formatDate(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-')
  },

  getRageDatePreview(startDate = null, endDate = null) {
    var datePreview = ''
    if (startDate) {
      datePreview = startDate
    }
    if (endDate) {
      var today = this.formatDate(new Date())
      let end = endDate == today ? 'Today' : endDate
      datePreview += ` - ${ end }`
    }
    return datePreview
  }
}