<template>
  <div>
    <v-chip v-for="tag in items" :key="tag.id" class="mr-2" outlined small>{{ tag.keyword }}</v-chip>
    <a v-if="extended" @click="toggle">{{ $vuetify.lang.t('$vuetify.ilm.labels.showLess') }}</a>
    <a v-else @click="toggle" :class="extras == 0 ? 'd-none' : ''">{{ `+${ extras } ${ $vuetify.lang.t('$vuetify.ilm.labels.more') }` }}</a>
  </div>
</template>

<script>
export default {

  name: 'OntologyTagsPreviewer',
  props: {
    tags: Array,
    max: { type: Number, default: 3 }
  },
  data () {
    return {
      items: [],
      extras: 0,
      extended: false
    }
  },
  methods: {
    showTags() {
      this.extras = 0
      this.items = Array.from(this.tags)
      let extras = this.items.length - this.max
      if (!this.extended && extras > 0) {
        this.extras = extras
        this.items = this.tags.slice(0, this.max)
      }
    },
    toggle() {
      this.extended = !this.extended
      this.showTags()
    }
  },
  created() {
    this.showTags()
  }
}
</script>

<style lang="css" scoped>
</style>