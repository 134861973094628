<template>
  <div>
    <v-tooltip v-model="show" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="copy">
          <v-icon color="grey lighten-1">mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.lang.t('$vuetify.ilm.labels.copyLink') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Tools from '@/modules/common/utils/Tools.js'

export default {

  name: 'CopyButton',
  props: {
    text: String
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    copy() {
      Tools.copyToClipboard(this.text)
    }
  }
}
</script>

<style lang="css" scoped>
</style>