import { render, staticRenderFns } from "./OntologyTagChart.vue?vue&type=template&id=c95d4fa6&scoped=true&"
import script from "./OntologyTagChart.vue?vue&type=script&lang=js&"
export * from "./OntologyTagChart.vue?vue&type=script&lang=js&"
import style0 from "./OntologyTagChart.vue?vue&type=style&index=0&id=c95d4fa6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c95d4fa6",
  null
  
)

export default component.exports