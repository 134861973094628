<template>
  <div class="margin-passages" v-html="html"></div>
</template>

<script>
import showdown from 'showdown'

export default {

  name: 'MarkdownToHtml',

  props: {
    source: String
  },
  data () {
    return {
      converter: null,
      token: null,
      documentId: '',
      text: '',
      html: ''
    }
  },
  methods: {
    textToHtml() {
      this.text = this.replaceLineSpaces(this.text)
      this.text = this.fixMDTables(this.text)
      this.html = this.converter.makeHtml(this.text)
    },
    fixMDTables(text) {
      var passageResult = ''
      if (text.indexOf('| --- |') != -1) {
        const pipeRegex = new RegExp("\\|", "g")
        let lastLine = ''
        // Iterate by new lines
        text.split('\n').forEach(line => {
          if (line == '| --- |') {
            if ((lastLine.match(pipeRegex) || []).length > 2) {
              line = '| --- | --- |'
            }
          }
          lastLine = line
          passageResult += line == '|' ? '\n' : `${line}\n`
        })
      }
      return passageResult.length != 0 ? passageResult : text
    },
    replaceLineSpaces(text) {
      text = text.replace(/\\n/g, '\n')
      return text
    }
  },
  created() {
    this.converter = new showdown.Converter()
    this.converter.setOption('tables', true)
    this.converter.setOption('simplifiedAutoLink', true)
    this.converter.setOption('excludeTrailingPunctuationFromURLs', true)
    this.text = this.source || ''
    this.textToHtml()
  },
  watch: {
    source: function() {
      this.text = this.source || ''
      this.textToHtml()
    }
  }
}
</script>
<style lang="scss">
div.margin-passages {
  table {
    margin-bottom: 15px;
  }
  td {
    border: 1px solid #c4c4c4;
    padding: 10px 4px;
  }
  th {
    padding: 10px 8px;
    border: 1px solid #282828;
    border-left: unset;
    text-align: left;
  }
  th:first-child {
    border-left: 1px solid #282828;
  }

}
//span.li-tagged-text {
//  color: var(--black);
//  padding: 2px 6px;
//  border-radius: 5px;
//}

</style>
