<template>
  <div>
    <v-icon>mdi-account-multiple</v-icon>
    {{ label }}
    <a v-if="extended" @click="toggle">{{ $vuetify.lang.t('$vuetify.ilm.labels.showLess') }}</a>
    <a v-else @click="toggle" :class="extras == 0 ? 'd-none' : ''">{{ `+${ extras } ${ $vuetify.lang.t('$vuetify.ilm.labels.more') }` }}</a>
  </div>
</template>

<script>
export default {

  name: 'AuthorsLabels',
  props: {
    authors: Array
  },
  data () {
    return {
      label: '',
      maxLength: 40,
      extras: 0,
      extended: false
    }
  },
  methods: {
    formatLabel() {
      let names = this.authors.map(author => author.name)
      if (this.extended) {
        this.label = names.join(', ')
      } else {
        let authors = []
        let sizeCounter = 0
        this.extras = 0
        names.forEach(name => {
          sizeCounter += name.length
          if (sizeCounter <= this.maxLength) {
            authors.push(name)
          } else {
            this.extras ++
          }
        })
        this.label = authors.join(', ')
      }
    },
    toggle() {
      this.extended = !this.extended
      this.formatLabel()
    }
  },
  created() {
    this.formatLabel()
  }
}
</script>

<style lang="css" scoped>
</style>