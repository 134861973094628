<template>
  <v-card elevation="0" class="article-separator">
    <v-card-text>
      <v-row class="pa-2" :class="expanded ? 'active-card-indentifier-container' : ''">
        <v-col cols="11">
          <v-row>
            <div class="mr-4">
              <favorite-button class="ml-4" :favorite="localArticle.starred_article" :action="toggleStarred" />
            </div>
            <div class="max-width-content-container">
              <div class="text-h6 initial-detail-row article-title" color="primary">
                <span v-if="cardMode">
                  <router-link class="no-underline" :to="`/ilm/${ article.project_id }/${ article.article_id }`">
                    <span class="primary--text openSans-400 fz-14">{{ localArticle.title }}</span>
                  </router-link>
                </span>
                <span class="openSans-400 fz-14" v-else>{{ localArticle.title }}</span>
              </div>
              <div class="my-3">
                <span
                  class="fz-12"><b>{{ $vuetify.lang.t('$vuetify.ilm.labels.excerpt')}}: </b>{{ localArticle.preview_excerpt }}</span>
              </div>
              <div>
                <div class="d-flex flex-row mb-3">
                  <span class="mr-3 fz-12">
                    <v-icon>mdi-text-box</v-icon>
                    <i>{{ localArticle.journal }}</i>
                  </span>
                  <authors-labels class="fz-12" v-if="localArticle.authors" :authors="localArticle.authors" />
                  <span class="mx-3 fz-12">
                    <v-icon>mdi-file-document-outline</v-icon>
                    <b>{{ localArticle.type }}</b>
                  </span>
                  <span class="fz-12">
                    <v-icon>mdi-bullhorn</v-icon> {{ localArticle.publication_date }}
                  </span>
                </div>
                <ontology-tags-previewer v-if="localArticle.ontology_tags" :tags="localArticle.ontology_tags" />
                <div v-show="expanded" class="my-5">
                  <div class="openSans-400 fz-12">{{ $vuetify.lang.t('$vuetify.ilm.labels.articleLinks') }}
                    ({{ localArticle.article_links.length }})</div>
                  <div class="mt-1" v-for="link in localArticle.article_links" :key="link">
                    <a :href="link" target="_blank">{{ link }}</a>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </v-col>
        <v-col class="d-flex align-start justify-center" cols="1">
          <copy-button :text="articleUrl" />
          <expand-button v-if="cardMode" class="ml-4" :expanded="expanded" :action="expand" />
        </v-col>
      </v-row>
      <div class="col-12 mt-4 pl-12" v-show="expanded">
        <v-row class="d-none">
          <v-col>
          <div class="d-flex justify-end">
            <a class="text-uppercase" @click="copyCitation">
              <v-icon class="mr-2" color="primary">mdi-content-copy</v-icon> {{ $vuetify.lang.t('$vuetify.ilm.buttons.copyCitation') }}
            </a>
          </div>
          </v-col>
        </v-row>
        <div class="bg-white">
          <div class="text-h6 mt-3">
            <v-icon>mdi-text-box-outline</v-icon>
            <span class="ml-1 fz-16 openSans-300 txt-primary-black">{{ $vuetify.lang.t('$vuetify.projects.labels.banner.summary') }}</span>
          </div>
          <ontology-tag-chart :ontology-chart-data="localArticle.ontology_tag_chart" class="mt-10 mb-10">
          </ontology-tag-chart>
          <div v-if="taggedText" class="summary-text">
            <markdown-to-html class="fz-16 paragraph-line-height" :source="taggedText"></markdown-to-html>
          </div>
          <div class="text-center mt-10 mb-10" v-else>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Tools from '@/modules/common/utils/Tools.js'

import CopyButton from '@/modules/common/components/CopyButton.vue'
import ExpandButton from '@/modules/common/components/ExpandButton.vue'
import FavoriteButton from '@/modules/common/components/FavoriteButton.vue'
import AuthorsLabels from '@/modules/intelligentLiteratureMonitoring/components/AuthorsLabels.vue'
import OntologyTagsPreviewer from '@/modules/intelligentLiteratureMonitoring/components/OntologyTagsPreviewer.vue'
import MarkdownToHtml from "@/modules/common/components/MarkdownToHtml";
import OntologyTagChart from "@/modules/intelligentLiteratureMonitoring/components/OntologyTagChart"

class Tagger {
  constructor(text, spans, ontologyTags) {
    this._text = text;
    this._spans = spans;
    this._colors = Object.fromEntries(ontologyTags.map(onto => {
      return [onto.ontology, onto.color]
    }))
    Object.entries(this._colors).forEach(([key, value]) => {
      let rgbaColor = this.getRGBA(value)
      this.createClass(`.li-tagged-text.${ key }`,
        `border: 1px solid ${rgbaColor}; background-color: ${ rgbaColor }`)
    })
  }
  createClass(name, rules) {
    var style = document.createElement('style');
    style.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(style);
    if (!(style.sheet || {}).insertRule)
      (style.styleSheet || style.sheet).addRule(name, rules);
    else
      style.sheet.insertRule(name + "{" + rules + "}", 0);
  }
  getRGBA(hexColor) {
    let hex = hexColor.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
    return `rgba(${r}, ${g}, ${b}, 0.3)`
  }
  tagArticle() {
    this._spans.sort((a, b) => {
      let alength = a.span_end_character - a.span_start_character
      let blength = b.span_end_character - b.span_start_character
      if (a.span_end_character > b.span_end_character) {
        return -1
      } else if (a.span_end_character < b.span_end_character) {
        return 1
      } else if (alength > blength) {
        return -1
      } else if (alength < blength) {
        return 1
      } else {
        return 0
      }
    })
    this.replaceHtmlTagChars("<", "&lt;")
    this.replaceHtmlTagChars(">", "&gt;")
    this._spans = this.removeOverlapping(this._spans)
    let tempText = this._text
    let shift = 0 // this is just for the demo
    for (let e of this._spans) {
      let start = e.span_start_character + shift;
      let end = e.span_end_character + shift;
      if (e != undefined && tempText.substring(start, end).indexOf('|') == -1) {
        let prefix = tempText.substring(0, start)
        let concept = tempText.substring(start, end)
        let suffix = tempText.substring(end, tempText.length)
        let styleClass = e.ontology_name
        let weight = e.predictionScore * 100
        let visibility = "" // "tags-invisible"
        tempText =
          `${prefix}<span class="li-tagged-text ${styleClass} ${visibility}" aria-valuetext="${e.synonym}" title="${styleClass.replace(/_/g, " ")} : ${Math.trunc(weight)}% ">${concept}</span>${suffix}`
      }
    }
    return tempText
  }

  replaceHtmlTagChars(a, b) {
    this._spans = this.shiftSpansForCharReplacement(this._text, a, b, this._spans)
    let regex = new RegExp(a, 'g');
    this._text = this._text.replace(regex, b)
  }
  removeOverlapping(spans) {
    spans.sort((a, b) => {
      return -1 * this.sortByPrecedence(a, b)
    })
    let winners = []
    for (const span of spans) {
      if (!winners.some(winner => this.spansOverlap(winner, span))) {
        winners.push(span)
      }
    }
    winners.sort(function (a, b) {
      return b.span_end_character - a.span_end_character
    })
    return winners
  }
  sortByPrecedence(a, b) {
    let scoreDifference = a.predictionScore - b.predictionScore
    if (scoreDifference != 0) {
      return scoreDifference
    } else {
      return a.in_text.length - b.in_text.length
    }
  }
  spansOverlap(a, b) {
    let bs = b.span_start_character
    let be = b.span_end_character
    let overlap = !(b == undefined || a == undefined) &&
      (this.isBetween(a.span_start_character, bs, be) || this.isBetween(a.span_end_character, bs, be))
    return overlap
  }
  isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0
  }
  indexes(source, find) {
    //returns all indexes of a regex into a string
    if (!source) {
      return [];
    }
    if (!find) {
      return [];
    }
    let regex = new RegExp(find, 'g');
    let result = [];
    let match
    while ((match = regex.exec(source)) != null) {
      result.push(match.index);
    }
    return result;
  }
  shiftSpansForCharReplacement(tempText, a, b, spans) {
    let charsToReplace = this.indexes(tempText, a)
    let shiftedSpans = spans
    if (charsToReplace.length > 0) {
      shiftedSpans = []
      spans.forEach((e) => {
        if (e != undefined) {
          let startShiftFilter = charsToReplace.filter(s => s < e.span_start_character)
          let startShift = startShiftFilter.length * (b.length - a.length)
          let endShift = charsToReplace.filter(s => s < e.span_end_character).length * (b.length - a.length)
          if (startShift > 0) {
            e['span_start_character'] = e.span_start_character + startShift
          }
          if (endShift > 0) {
            e['span_end_character'] = e.span_end_character + endShift
          }
        }
        shiftedSpans.push(e)
      })
    } else {
      //console.log(`no chars to replace`)
    }
    return shiftedSpans
  }
}

export default {
  name: 'ArticleCard',
  components: {OntologyTagChart, CopyButton, ExpandButton, FavoriteButton, AuthorsLabels, OntologyTagsPreviewer, MarkdownToHtml },
  props: {
    article: Object,
    fullArticle: { type: Boolean, default: false },
    mode: { type: String, default: 'card' },
    getFullArticle: Function,
    allExpanded: Boolean,
    clearStars: Boolean,
    setStarredArticle: Function
  },
  data () {
    return {
      localArticle: null,
      expanded: false,
      cardMode: true,
      taggedText:null
    }
  },
  methods: {
    expand() {
      if (!this.expanded && !this.localArticle.spans) {
        this.getFullArticle(this.localArticle.article_id).then(async response => {
          this.localArticle = response.data
          await this.tagArticle()
          this.$emit('update:fullArticle', true)
        })
      } else {
        this.tagArticle()
      }
      this.expanded = !this.expanded
    },
    toggleStarred() {
      this.localArticle.starred_article = (this.localArticle.starred_article) ? false : true
      this.setStarredArticle(this.localArticle.article_id, this.localArticle.starred_article)
    },
    tagArticle() {
      console.log(`tagging article with ${this.localArticle.spans.length} spans`)
      let tagger = new Tagger(this.localArticle.summary, this.localArticle.spans, this.localArticle.ontology_tags)
      this.taggedText = tagger.tagArticle()
    },
    copyCitation() {
      Tools.copyToClipboard("copyCitation")
    }
  },
  computed: {
    articleUrl() {
      return `${window.location.host}/ilm/${ this.$route.params.projectId }/${ this.article.article_id }`
    }
  },
  watch: {
    allExpanded() {
      if (this.allExpanded != this.expanded) {
        this.expand()
      }
    },
    clearStars() {
      if (this.localArticle.starred_article) {
        this.toggleStarred()
      }
    }
  },
  created() {
    this.localArticle = this.article
    if (this.mode == 'article') {
      this.cardMode = false
      this.expand()
    }
  }
}
</script>

<style lang="scss" scoped>
  .no-underline {
    text-decoration: none;
    color: inherit;
  }

  ::v-deep {
    span.li-tagged-text {
      padding: 2px 3px;
      border-radius: 5px;
    }
  }

  div.summary-text {
    max-width: 1000px;
  }

  .initial-detail-row {
    line-height: normal;
  }

  .active-card-indentifier-container {
    background-color: #F2F5F8;
  }

  .paragraph-line-height {
    line-height: 30px;
  }

  .max-width-content-container {
    max-width: 70%; 
  }
</style>
